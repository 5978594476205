// import {useIntl} from 'react-intl'
// import {PageTitle} from '../../../_metronic/layout/core'
// import {
//   ListsWidget1,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   MixedWidget10,
//   MixedWidget11,
//   MixedWidget2,
//   StatisticsWidget5,
//   TablesWidget10,
//   TablesWidget5,
// } from '../../../_metronic/partials/widgets'

// const DashboardPage = () => (
//   <>
//     {/* begin::Row */}
//     <div className='row g-5 g-xl-8'>
//       <div className='col-xl-4'>
//         <StatisticsWidget5
//           className='card-xl-stretch mb-xl-8'
//           svgIcon='basket'
//           color='body-white'
//           iconColor='primary'
//           title='Shopping Cart'
//           description='Lands, Houses, Ranchos, Farms'
//           titleColor='gray-900'
//           descriptionColor='gray-400'
//         />
//       </div>

//       <div className='col-xl-4'>
//         <StatisticsWidget5
//           className='card-xl-stretch mb-xl-8'
//           svgIcon='element-11'
//           color='primary'
//           iconColor='white'
//           title='Appartments'
//           description='Flats, Shared Rooms, Duplex'
//           titleColor='white'
//           descriptionColor='white'
//         />
//       </div>

//       <div className='col-xl-4'>
//         <StatisticsWidget5
//           className='card-xl-stretch mb-5 mb-xl-8'
//           svgIcon='left'
//           color='dark'
//           iconColor='gray-100'
//           title='Sales Stats'
//           description='50% Increased for FY20'
//           titleColor='gray-100'
//           descriptionColor='gray-100'
//         />
//       </div>
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row g-5 g-xl-8'>
//       {/* begin::Col */}
//       <div className='col-xl-4'>
//         <ListsWidget1 className='card-xl-stretch mb-xl-8' />
//       </div>
//       {/* end::Col */}

//       {/* begin::Col */}
//       <div className='col-xl-8'>
//         <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
//       </div>
//       {/* end::Col */}
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gy-5 g-xl-8'>
//       <div className='col-xxl-4'>
//         <MixedWidget2
//           className='card-xl-stretch mb-xl-8'
//           chartColor='danger'
//           chartHeight='200px'
//           strokeColor='#cb1e46'
//         />
//       </div>
//       <div className='col-xxl-4'>
//         <ListsWidget5 className='card-xxl-stretch' />
//       </div>
//       <div className='col-xxl-4'>
//         <MixedWidget10
//           className='card-xxl-stretch-50 mb-5 mb-xl-8'
//           chartColor='primary'
//           chartHeight='150px'
//         />
//         <MixedWidget11
//           className='card-xxl-stretch-50 mb-5 mb-xl-8'
//           chartColor='primary'
//           chartHeight='175px'
//         />
//       </div>
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gy-5 gx-xl-8'>
//       <div className='col-xxl-4'>
//         <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
//       </div>
//       <div className='col-xl-8'>
//         <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
//       </div>
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gy-5 g-xl-8'>
//       <div className='col-xl-4'>
//         <ListsWidget2 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidget6 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
//         {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
//       </div>
//     </div>
//     {/* end::Row */}
//   </>
// )

// const DashboardWrapper = () => {
//   const intl = useIntl()
//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
//       <DashboardPage />
//     </>
//   )
// }

// export {DashboardWrapper}
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget1,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  StatisticsWidget5,
  TablesWidget10,
  TablesWidget5,
} from '../../../_metronic/partials/widgets'
import { useAuth } from '../../modules/auth'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { channel } from 'diagnostics_channel'
import moment from 'moment'
let currentdate: string;
let updateddate:string ;
let table_length = 0;
let total_productsLength = 0;
let table_buyersLength = 0;
let total_adminsLength = 0;
let total_products_atriskLength = 0;
let total_products_atbonusLength = 0;
let sale_price_atriskLength = 0;
let total_price_atbonus = 0
let totalnormalprice_productaproval = 0
let totalsaleprice_productaproval = 0
let total_price_mapping_feedLength=0

const DashboardPage = ({ checkUser }) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <Link to={"total-products"} className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch h-100'
          svgIcon='basket'
          color='body-white'
          // qwe="#"
          iconColor='primary'
          title={total_productsLength === undefined ? "0" : total_productsLength?.toString()}
          description='Total Products'
          titleColor='gray-900'
          descriptionColor='gray-500'
        />
      </Link>

      <Link to={"normalprice-atrisk"} className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch h-100'
          svgIcon='trash'
          // qwe="#"
          color='body-white'
          iconColor='danger'
          title={total_products_atriskLength === undefined ? "0" : total_products_atriskLength?.toString()}
          // description='Products At Risk'
          description='Normal price/At Risk'
          titleColor='gray-900'
          descriptionColor='gray-500'
        />
      </Link>

      <Link to={"normalprice-atmargin"} className='col-xl-3'>
        <StatisticsWidget5
          // qwe="product-approval"
          className='card-xl-stretch h-100 mb-5'
          svgIcon='add-item'
          color='body-white'
          iconColor='primary'
          title={total_products_atbonusLength === undefined ? "0" : total_products_atbonusLength?.toString()}
          // description='Product at Bonus'
          description='Normal price/At Margin'
          titleColor='gray-900'
          descriptionColor='gray-500'
        />
      </Link>

      <Link to={"saleprice-atrisk"} className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch h-100'
          svgIcon='trash'
          // qwe="#"
          color='body-white'
          iconColor='danger'
          title={sale_price_atriskLength === undefined ? "0" : sale_price_atriskLength?.toString()}
          description='Sale price/At Risk'
          titleColor='gray-900'
          descriptionColor='gray-500'
        />
      </Link>


      <Link to={"saleprice-atmargin"} className='col-xl-3'>
        <StatisticsWidget5
          // qwe="product-approval"
          className='card-xl-stretch h-100 mb-5'
          svgIcon='add-item'
          color='body-white'
          iconColor='primary'
          // title={sale_price_atbonusLength === undefined ? "0" : sale_price_atbonusLength?.toString()}
          title={total_price_atbonus === undefined ? "0" : total_price_atbonus?.toString()}

          description='Sale Price/At Margin'
          titleColor='gray-900'
          descriptionColor='gray-500'
        />
      </Link>

      <Link to={"Normalprice-productapproval"} className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch h-100 mb-5'
          svgIcon='save-2'
          // qwe="#"
          color='body-white'
          iconColor='success'
          title={totalnormalprice_productaproval === undefined ? "0" : totalnormalprice_productaproval?.toString()}
          description='Normal Price Product Requiring Approval'
          titleColor='gay-900'
          descriptionColor='gray-500' />
      </Link>

      <Link to={"Saleprice-productapproval"} className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch h-100 mb-5'
          svgIcon='save-2'
          // qwe="#"
          color='body-white'
          iconColor='info'
          title={totalsaleprice_productaproval === undefined ? "0" : totalsaleprice_productaproval?.toString()}
          description='Sale Price Product Requiring Approval'
          // description={
          //   <>
          //     Sale price<br />
          //     Product Requiring Approval
          //   </>
          // }
          titleColor='gay-900'
          descriptionColor='gray-500' />
      </Link>
      {checkUser !== 'Buyer' && (
        <React.Fragment>
          {/* <Link to={"total-products"} className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch h-100 mb-5'
              svgIcon='shop'
              color='body-white'
              // qwe="#"
              iconColor='success'
              title={total_productsLength === undefined ? "0" : total_productsLength?.toString()}
              description='Total Products'
              titleColor='gray-900'
              descriptionColor='gray-500'
            />
          </Link> */}
          <Link to={"total-buyers"} className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch h-100 mb-5'
              svgIcon='basket-ok'
              color='body-white'
              // qwe="#"
              iconColor='warning'
              title={table_buyersLength?.toString()}
              description='Total Buyers'
              titleColor='gray-900'
              descriptionColor='gray-500'
            />
          </Link>
          <Link to={"total-admins"} className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch h-100 mb-5'
              svgIcon='element-11'
              // qwe="#"
              color='body-white'
              iconColor='primary'
              title={total_adminsLength && total_adminsLength === undefined ? "0" : total_adminsLength?.toString()}
              description='Total Admins'
              titleColor='gray-900'
              descriptionColor='gray-500'
            />
          </Link>
          {/* <Link to={"total-admins"} className='col-xl-3'> */}
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch h-100 mb-5'
              svgIcon='basket'
              // qwe="#"
              color='body-white'
              iconColor='primary'
              title={total_price_mapping_feedLength && total_price_mapping_feedLength === undefined ? "0" : total_price_mapping_feedLength?.toString()}
              description="Total Products Price doesn't Feed"
              titleColor='gray-900'
              descriptionColor='gray-500'
            />
            </div>
          {/* </Link> */}
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch h-100 mb-5'
              svgIcon='calendar-tick'
              // qwe="#"
              color='body-white'
              iconColor='dark'
              title={currentdate}
              description='Last Currency Deviation Captured'
              titleColor='gray-900'
              descriptionColor='gray-500'
            />
          </div>
          <div className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch h-100 mb-5'
              svgIcon='calendar-tick'
              color='light-white'
              iconColor='info'
              // qwe="#"
              title={updateddate}
              description='Last IBS Update'
              titleColor='gray-900'
              descriptionColor='gray-500'
            />
          </div>
        </React.Fragment>
      )}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  const API_URL = process.env.REACT_APP_KT_API_URL
  const tokenString: any = localStorage.getItem('kt-auth-react-v')
  const token = JSON.parse(tokenString)
  const PRODUCT_API_URL = `${API_URL}/product`
  // const [itemsdata_length, setItemsData_length] = useState<any>([])
  const [total_product_length, setTotal_product_length] = useState<any>([])
  const [total_buyers_length, setTotal_buyers_length] = useState<any>([])
  const [normalprice_atrisk, setProductsrisk] = useState<any>([])
  const [normalprice_atbonus, setProductsatbonus] = useState<any>([])
  const [saleprice_atrisk, setSaleprice_atrisk] = useState<any>([])
  const [total_price_mapping_feed_length, setTotal_price_mapping_feed_length] = useState<any>(null)
  const [saleprice_atbonus, setSaleprice_atbonus] = useState<any>([])
  const [normalprice_productaproval, setNormalprice_productaproval] = useState<any>([])
  const [saleprice_productaproval, setSaleprice_productaproval] = useState<any>([])
  const [getlastproduct, setGetlastproduct] = useState<any>([])
  const [totaladmins, setTotaladmins] = useState<any>([])
  const { currentUser } = useAuth()

  // send data to dashboard
  total_productsLength = total_product_length;
  table_buyersLength = total_buyers_length;
  total_adminsLength = totaladmins;
  total_products_atriskLength = normalprice_atrisk;
  total_products_atbonusLength = normalprice_atbonus
  sale_price_atriskLength = saleprice_atrisk
  total_price_atbonus = saleprice_atbonus
  totalnormalprice_productaproval = normalprice_productaproval
  totalsaleprice_productaproval = saleprice_productaproval
  total_price_mapping_feedLength = total_price_mapping_feed_length

// console.log("getlastproduct::",getlastproduct)
  // Datefunction
  const dateObj = new Date();
  const hours = dateObj.getHours();
  const minutes = ('0' + dateObj.getMinutes()).slice(-2);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12;
  const day = ('0' + dateObj.getDate()).slice(-2);
  const month = getMonthName(dateObj.getMonth());
  const year = dateObj.getFullYear();
  const formattedDate = `${day} ${month} ${year} , ${displayHours}:${minutes} ${ampm}`;
  currentdate = formattedDate
  updateddate=getlastproduct
  function getMonthName(monthIndex) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[monthIndex];
  }

  // normal-price product approval
  const normalpriceProductApproval = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userType = currentUser.roles[0]?.id;
        const userId = currentUser?.id
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        // if (userType !== null && userType !== undefined) {
        //   params.append("search", userType?.toString())
        // }
        params.append("userId", userId?.toString())
      }
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/ApprovalWorkflowsNormalProduct?&${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setNormalprice_productaproval(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    normalpriceProductApproval()
  }, [])


  // sale-price product approval
  const salepriceProductApproval = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userType = currentUser.roles[0]?.id;
        const userId = currentUser?.id
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/ApprovalWorkflowsSaleProduct?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setSaleprice_productaproval(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    salepriceProductApproval()
  }, [])

  // Check length of sale activities
  // const saleActivitiesLength = itemsdata_length?.map((data) =>
  //   data.channels?.map((item) =>
  //     item?.channel_calculations?.map((calculation) =>
  //       calculation?.sale_activities?.length || 0
  //     )
  //   )
  // );

  // // Flatten the array and calculate the total length of sale activities
  // const totalSaleActivitiesLength = saleActivitiesLength.flat(2).reduce((sum, length) => sum + length, 0);

  // // Set table length based on is_approval
  // const d = itemsdata_length?.map((data) =>
  //   data.channels?.map((item) =>
  //     item?.channel_calculations?.filter((data) =>
  //       data.is_approval === "requested"
  //     )
  //   )
  // );

  // // Flatten the nested arrays
  // const totalcalculationLength = d.flat(2);
  // table_length = totalcalculationLength.length + totalSaleActivitiesLength;


  // useEffect(() => {
  //   apicall_check_length()
  // }, [])

  // total_products
  const total_products = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userId = currentUser?.id
        const userType = currentUser.roles[0]?.id;
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/GetAllProducts?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setTotal_product_length(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    total_products()
  }, [])
  //

    // total_price_mapping_feed
    const total_price_mapping_feed = async () => {
      try {
        let params = new URLSearchParams();
        if (currentUser?.roles && currentUser.roles.length > 0) {
          const userId = currentUser?.id
          const userType = currentUser.roles[0]?.id;
          if (userType !== null && userType !== undefined) {
            params.append("user_type_id", userType?.toString())
          }
          params.append("userId", userId?.toString())
        }
        const queryString = params.toString();
        const response = await fetch(`${API_URL}/sellerProduct/price-feed/count`, {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            'Content-Type': 'application/json',
          },
        })
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        const data = await response.json()
        // console.log("hvjfhd",data)
        setTotal_price_mapping_feed_length(data?.data?.meta?.totalItems)
      } catch (error) {
        // console.error(error)
      }
    }
  
    useEffect(() => {
      total_price_mapping_feed()
    }, [])
    //

  const getlastProduct = async () => {
    try {
      const response = await fetch(`${API_URL}/product/IbsSyncDetails`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      // Parsing the date and using UTC methods

      const dateObj = new Date(data.data.last_ibs_sync);
      // const dateObj1 = moment().format('MMMM Do YYYY, hh:mm:ss a')
      const hours = dateObj.getHours();
      const minutes = ('0' + dateObj.getMinutes()).slice(-2);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12;
      const day = ('0' + dateObj.getDate()).slice(-2);
      const month = getMonthName(dateObj.getMonth());
      const year = dateObj.getFullYear();
      const formattedDate = `${day} ${month} ${year} , ${displayHours}:${minutes} ${ampm}`;
      setGetlastproduct(formattedDate)
    } catch (error) {
      // console.error(error)
    }
  }
  useEffect(() => {
    getlastProduct()
  }, [])


  // total_buyers
  const total_buyers = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userId = currentUser?.id
        const userType = currentUser.roles[0]?.id;
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/GetAllBuyer?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setTotal_buyers_length(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }
  useEffect(() => {
    total_buyers()
  }, [])
  //

  // normal price atrisk
  const products_atrisk = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userId = currentUser?.id
        const userType = currentUser.roles[0]?.id;
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      params.append("fillterProductAtRisk", true.toString())
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/NormalPriceProduct?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setProductsrisk(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    products_atrisk()
  }, [])
  //

  // Normal price at bonus
  const products_atbonus = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userId = currentUser?.id
        const userType = currentUser.roles[0]?.id;
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      params.append("fillterProductAtRisk", false.toString())
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/NormalPriceProduct?${queryString}`, {
        // const response = await fetch(`${API_URL}/dashboard/?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setProductsatbonus(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    products_atbonus()
  }, [])


  // sale price at risk
  const salePrice_atrisk = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userId = currentUser?.id
        const userType = currentUser.roles[0]?.id;
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      params.append("fillterProductAtRisk", true.toString())
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/SalePriceProduct?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setSaleprice_atrisk(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    salePrice_atrisk()
  }, [])


  // sale price at bonus
  const salePrice_atBonus = async () => {
    try {
      let params = new URLSearchParams();
      if (currentUser?.roles && currentUser.roles.length > 0) {
        const userId = currentUser?.id
        const userType = currentUser.roles[0]?.id;
        if (userType !== null && userType !== undefined) {
          params.append("user_type_id", userType?.toString())
        }
        params.append("userId", userId?.toString())
      }
      params.append("fillterProductAtRisk", false.toString())
      const queryString = params.toString();
      const response = await fetch(`${API_URL}/dashboard/SalePriceProduct?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setSaleprice_atbonus(data?.data?.meta.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    salePrice_atBonus()
  }, [])

  // Total Admins
  const total_admins = async () => {
    try {
      const response = await fetch(`${API_URL}/dashboard/GetAllAdmin`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()
      setTotaladmins(data?.data?.meta?.totalItems)
    } catch (error) {
      // console.error(error)
    }
  }

  useEffect(() => {
    total_admins()
  }, [])
  //


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage checkUser={currentUser?.roles?.[0]?.name} />
    </>
  )
}

export { DashboardWrapper }
