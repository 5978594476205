export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  let getactiveid = localStorage.getItem('active_button_productId');
  let profitmargin_active_button_productId = localStorage.getItem('profitmargin_active_button_productId');
  let sellercloud_active_button_productId = localStorage.getItem('sellercloud_active_button_productId');
  let manage_product_active_button_productId = localStorage.getItem('manage_products_active_button');
  let buyer_active_button_productId = localStorage.getItem('buyer_active_button');
  let userList_active_button_productId = localStorage.getItem('userList_active_button');
  let amazon_active_button_productid = localStorage.getItem('amazon_active_productid');


 
  const current = getCurrentUrl(pathname)

  // console.log("gjerjrhejrrjer",current ,url,getactiveid)

  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (getactiveid && url === "allProducts/list" && current === `/allProducts/${getactiveid}/detail` || getactiveid && url === "allProducts/list" && current === `/allProducts/${getactiveid}/feeds`) {
    return true
  }
  
  if (profitmargin_active_button_productId && url === "all-Products/list" && current === `/all-Products/${profitmargin_active_button_productId}/detail` || profitmargin_active_button_productId && url === "all-Products/list" && current === `/all-Products/${profitmargin_active_button_productId}/feeds`) {
    return true
  }

  if (sellercloud_active_button_productId && url === "seller-product/list" && current === `/seller-product/${sellercloud_active_button_productId}/detail` || sellercloud_active_button_productId && url === "seller-product/list" && current === `/seller-product/${sellercloud_active_button_productId}/feeds`) {
    return true
  }

  if (manage_product_active_button_productId && url === "products/list" && current === `/products/${manage_product_active_button_productId}/detail` || manage_product_active_button_productId && url === "products/list" && current === `/products/${manage_product_active_button_productId}/feeds` || manage_product_active_button_productId && url === "products/list" && current === `/products/${manage_product_active_button_productId}/buyers`) {
    return true
  }

  if (userList_active_button_productId && url === "users/list" && current === `/users/${userList_active_button_productId}/overview`){
    return true
  }

  if (buyer_active_button_productId && url === "buyers/list" && current === `/buyers/${buyer_active_button_productId}/activity` || buyer_active_button_productId && url === "buyers/list" && current === `/buyers/${buyer_active_button_productId}/products` || buyer_active_button_productId && url === "buyers/list" && current === `/buyers/${buyer_active_button_productId}/amazon-products`) {
    return true
  }

  if (amazon_active_button_productid && url === "amazon-product/list" && current === `/amazon-product/${amazon_active_button_productid}/detail` || amazon_active_button_productid && url === "amazon-product/list" && current === `/amazon-product/${amazon_active_button_productid}/feeds` || amazon_active_button_productid && url === "amazon-product/list" && current === `/amazon-product/${amazon_active_button_productid}/users`) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

// import { useParams } from "react-router-dom";

// export function getCurrentUrl(pathname: string) {
//   return pathname.split(/[?#]/)[0];
// }

// export function checkIsActive(pathname: string, url: string, id: string) {
//   const current = getCurrentUrl(pathname);

//   console.log("/allProducts/list", current, url, current === url);

//   if (!current || !url) {
//     return false;
//   }

//   if (current === url) {
//     return true;
//   }

//   if (current === `/allProducts/${id}/detail` && url === "allProducts/list") {
//     return true;
//   }

//   if (current.indexOf(url) > -1) {
//     return true;
//   }

//   return false;
// }

// const MyComponent = () => {
//   const { id }: any = useParams();
//   const pathname = window.location.pathname; // Get the current pathname

//   const isActive = checkIsActive(pathname, "allProducts/list", id);

//   return (
//     <div>
//       {isActive ? <span>Active</span> : <span>Inactive</span>}
//     </div>
//   );
// };

// export default MyComponent;
