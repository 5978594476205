// reducers/counterReducer.js
import {createSlice} from '@reduxjs/toolkit'
const sellerproductshowinventory = createSlice({
  name: 'sellerproductshowinventory',
  initialState: {
    payloadData: '',
  },
  reducers: {
    sellerProductpayloaddata(state:any, action:any) {
   if(action?.payload){
    return action.payload
   }else{
    return null
   }
    },
  },
})
export default sellerproductshowinventory.reducer
export const {sellerProductpayloaddata} = sellerproductshowinventory.actions
