import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

// Assuming token is an object with an access_token field
const tokenString = localStorage.getItem('kt-auth-react-v');
const token = tokenString ? JSON.parse(tokenString) : null;
const API_URL = process.env.REACT_APP_KT_API_URL;

export default function Dummy() {
  const location = useLocation();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // API call function
  const apicall = async () => {
    setLoading(true);
    setError(null); 

    const urlParams = new URLSearchParams(location.search);
    const params_sku = urlParams.get('sku');
    const param_status = urlParams.get('status');

    if (!params_sku || !param_status || !token) {
      setError("Missing parameters (sku, status, or token). Cannot make API call.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/ibsProduct/sfp?sku=${params_sku}&status=${param_status}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("API Data:", responseData);
    //   window.history.pushState({}, '', `?sku=${responseData?.data?.updatedProduct["SKU No."]}&status=TRUE`);
      setData(responseData?.data?.updatedProduct || null);
      setLoading(false);
    } catch (error) {
      console.error('Error making API call:', error);
      setError("Failed to fetch data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    apicall();
  }, [location.search]);



// useEffect(()=>{
//     window.history.pushState({}, '', "?sku=&status=");
// },[])


return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {data && (
        <div>
          <p><strong>SKU No : </strong> {data["SKU No."]}</p>
          <p><strong>sfp : </strong> {data.sfp}</p>
        </div>
      )}
    </>
  );
  
}
